import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'reactstrap-date-picker';
import moment from 'moment';
import { Button, Form, Label, Input } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Switch } from '@material-ui/core';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as tenantAPI from '../../Api/tenants';
import * as billsAPI from '../../Api/bills';
import * as Util from '../Util/util';
import * as MyFuncs from '../Util/myFunctions';

const addFeeStyles = {
    paddingLeft: '1%',
    paddingRight: '1%',
    paddingTop: '1%',
    paddingBottom: '2%',
};

const ApplyAddtionalFee = (props) => {
    const history = useHistory();
    const login = useSelector((state) => state.login);
    const user = login.user;
    const company = login.company;
    const propertyID = login.selectedPropertyID;
    const userID = user.id;
    const companyID = company.id;
    const admin = user.securityLevel;

    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [descriptions, setDescriptions] = useState([]);

    const {
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            setProperties(await billsAPI.getProperties(userID));
            const tenants = await tenantAPI.getTenants({
                multiprop: false,
                userID,
                propertyID,
                companyID,
            });
            let arr = [];
            for (const t of tenants) {
                arr.push({
                    value: t.TenantID,
                    label: `${t.TenantFName} ${t.TenantLName} - Unit: ${t.UnitName}`,
                });
            }
            setTenants(arr);
            setDescriptions(await tenantAPI.getChargeTypes());
            setLoading(false);
        }
        fetchData();
    }, [propertyID, userID, companyID]);

    const submitForm = async (data) => {
        if (data.tenantID.length === 0) {
            NotificationManager.warning('Please select at least one tenant.', 'Warning!');
            return;
        }

        let tenantsArr = [];
        data.tenantID.map((element) => tenantsArr.push({ tenantID: element.value }));

        let charges = [];
        console.log('data', data);
        const electric = MyFuncs.getFormattedNum(data.electric);
        const trash = MyFuncs.getFormattedNum(data.trash);
        const water = MyFuncs.getFormattedNum(data.water);
        const gas = MyFuncs.getFormattedNum(data.gas);
        const sewer = MyFuncs.getFormattedNum(data.sewer);

        if (parseFloat(electric) > 0) charges.push({ id: 51, amount: electric });
        if (parseFloat(trash) > 0) charges.push({ id: 56, amount: trash });
        if (parseFloat(water) > 0) charges.push({ id: 53, amount: water });
        if (parseFloat(gas) > 0) charges.push({ id: 52, amount: gas });
        if (parseFloat(sewer) > 0) charges.push({ id: 55, amount: sewer });

        if (charges.length === 0) {
            NotificationManager.warning('Please add at least one charge.', 'Warning!');
            return;
        }

        // Add the Fee
        setLoading(true)
        for (let i = 0; i < charges.length; i++) {
            const charge = charges[i];
            const res = await tenantAPI.applyAdditionalUtilityCharges({
                chargeTypeID: charge.id,
                tenants: tenantsArr,
                transactionType: 1,
                transactionDate: data.charge_date,
                amount: MyFuncs.getFormattedNum(charge.amount),
                userID,
                depositSourceID: 1,
                propertyID: parseInt(data.propertyID),
                admin,
            });

            if (res !== 0) {
                NotificationManager.error('Error processing your request. Please, contact us.', 'Error!');
                return;
            }
        }

        NotificationManager.success('Additional Fee applied sucessfully.', 'Success!');
        setLoading(false);
        reset({
            tenantID: '',
            electric: '',
            trash: '',
            water: '',
            gas: '',
            sewer: '',
            charge_date: data.charge_date,
        }, {
            keepErrors: false,
            keepDirty: false,
        })
    };

    const handleSelectTenant = (selected) => {
        setValue('tenantID', selected);
    };

    const render = () => {
        if (loading) {
            return (
                <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Apply Additional Fee...'}>
                    <LinearProgress />
                </RctCollapsibleCard>
            );
        } else {
            return (
                <Main>
                    <div style={addFeeStyles}>
                        <div className="page-title d-flex justify-content-between align-items-center">
                            <div className="page-title-wrap">
                                <i
                                    className="ti-angle-left"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => history.push('/tenants/viewAll')}
                                ></i>
                                <h2>
                                    <span>Apply Additional Utility Charge</span>
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <RctCollapsibleCard>
                                    <Form onSubmit={handleSubmit(submitForm)}>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <Label for="tenantID" className="mr-sm-10">
                                                    Tenant
                                                </Label>
                                                <Controller
                                                    name="tenantID"
                                                    control={control}
                                                    defaultValue={[]}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isMulti
                                                            name="colors"
                                                            options={tenants}
                                                            className="basic-multi-select form-control no-padding"
                                                            classNamePrefix="select"
                                                            onChange={handleSelectTenant}
                                                            style={Util.setErrorStyle(errors.tenantID)}
                                                        />
                                                    )}
                                                />
                                                {errors.tenantID && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="charge_date" className="mr-sm-10">
                                                    Charge Date
                                                </Label>
                                                <Controller
                                                    name="charge_date"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            id="charge_date"
                                                            style={Util.setErrorStyle(errors.charge_date)}
                                                        />
                                                    )}
                                                />
                                                {errors.charge_date && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-sm-2">
                                                <Label for="electric" className="mr-sm-10">
                                                    Electric
                                                </Label>
                                                <Controller
                                                    name="electric"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="electric"
                                                            style={Util.setErrorStyle(errors.electric)}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.electric && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="trash" className="mr-sm-10">
                                                    Trash
                                                </Label>
                                                <Controller
                                                    name="trash"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="trash"
                                                            style={Util.setErrorStyle(errors.trash)}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.trash && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="amount" className="mr-sm-10">
                                                    Water
                                                </Label>
                                                <Controller
                                                    name="water"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="water"
                                                            style={Util.setErrorStyle(errors.water)}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.water && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="gas" className="mr-sm-10">
                                                    Gas
                                                </Label>
                                                <Controller
                                                    name="gas"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="gas"
                                                            style={Util.setErrorStyle(errors.gas)}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.gas && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-2">
                                                <Label for="sewer" className="mr-sm-10">
                                                    Sewer
                                                </Label>
                                                <Controller
                                                    name="sewer"
                                                    control={control}
                                                    defaultValue={0}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <NumberFormat
                                                            {...field}
                                                            id="sewer"
                                                            style={Util.setErrorStyle(errors.sewer)}
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            className="form-control"
                                                        />
                                                    )}
                                                />
                                                {errors.sewer && (
                                                    <span style={{ color: 'red' }} role="alert">
                                                        required
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <Button type="submit" color="primary" style={{ marginTop: '20px' }}>
                                            Apply Charge
                                        </Button>
                                    </Form>
                                </RctCollapsibleCard>
                            </div>
                        </div>
                    </div>
                </Main>
            );
        }
    };

    return render();
};

export default ApplyAddtionalFee;

