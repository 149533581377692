import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup } from '@material-ui/core';
import { Button, Form, Label, Input } from 'reactstrap';
import DatePicker from 'reactstrap-date-picker';
import Multiselect from 'multiselect-react-dropdown';
import Main from '../Main';
import RctCollapsibleCard from '../Helpers/RctCollapsibleCard/RctCollapsibleCard';
import LinearProgress from '../Util/LinearProgress';
import * as Constants from '../Util/constants';
import * as reportsAPI from '../../Api/reports';
import { formatDate } from './helperFunctions';

import BalanceSheet from './BalanceSheet';
import ChartOfAccounts from './ChartOfAccounts';
import Delinquency from './Delinquency';
import GeneralLedger from './GeneralLedger';
import ProfitAndLoss from './ProfitAndLoss';
import WorkOrderSummary from './WorkOrderSummary';
import XWorkOrderSummary from './XWorkOrderSummary';
import RentRoll from './RentRoll';
import TenantOnlinePayments from './TenantOnlinePayments';
import TenantPayments from './TenantPayments';
import XTenantPayments from './XTenantPayments';
import VacancyReport from './VacancyReport';
import XVacancyReport from './XVacancyReport';
import XDelinquencySummary from './XDelinquencySummary';
import ProfitAndLossByMonth from './ProfitAndLossByMonth';
import TenNinetyNineInt from './TenNinetyNineInt';
import TenNinetyNineMisc from './TenNinetyNineMisc';
import TenNinetyNineNEC from './TenNinetyNineNec';
import ApplicationSummary from './ApplicationSummary';
import BackgroundCheck from './BackgroundCheck';
import BoxScore from './BoxScore';
import Concession from './Concession';
import CreditCheck from './CreditCheck';
import DelinquencyDate from './DelinquencyDate';
import DepositListUnit from './DepositListUnit';
import DepositList from './DepositList';
import DownUnit from './DownUnit';
import EscrowReserve from './EscrowReserve';
import EvictionSummary from './EvictionSummary';
import VehicleReport from './VehicleReport';
import ExpensesUnit from './ExpensesUnit';
import LeaseExpiration from './LeaseExpiration';
import LeaseRenewals from './LeaseRenewals';
import LeaseSummary from './LeaseSummary';
import LeaseViolation from './LeaseViolation';
import MoveInOut from './MoveInOut';
import MoveOutReason from './MoveOutReason';
import NonAllocPayments from './NonAllocPayments';
import NonRecCharges from './NonRecCharges';
import Nsf from './Nsf';
import Payables from './Payables';
import PositiveCredit from './PositiveCredit';
import PrePaidRent from './PrePaidRent';
import Quickbooks from './QuickbooksExport';
import RentRollActual from './RentRollActual';
import SecurityDeposit from './SecurityDeposit';
import TenantPortal from './TenantPortalAcc';
import TenantTenure from './TenantTenure';
import TenantInsurance from './TenantInsurance';
import TrafficSource from './TrafficSource';
import TrafficSourceAll from './TrafficSourceAll';
import TransChangeLog from './TransChangeLog';
import UnitAvailList from './UnitAvailList';
import UnitListing from './UnitListing';
import VacancyMonth from './VacancyMonth';
import BudgetProject from './BudgetProject';
import LeaseRenewalsMonth from './LeaseRenewalsMonth';
import OccupancyMonth from './OccupancyMonth';
import LeasingAgent from './LeasingAgent';
import ProjectedOccupancy from './ProjectedOccupancy';
import BudgetProjectActual from './BudgetProjectActual';
import OnlinePaymentSummary from './OnlinePaymentSummary';
import moment from 'moment';

const btnStyle = {
    fontSize: '14px',
};

const Reports = (props) => {
    const searchState = props.location.state?.reportSearch;
    const propertyVal = props.location.state?.selectedValue;
    const [loading, setLoading] = useState(false);
    const [reportName, setReportName] = useState('');
    const [reportOptions, setReportOptions] = useState({});
    const [fetchReports, setFetchReports] = useState();
    const [reportId, setReportId] = useState(null);
    const [reportButton, setReportButton] = useState(false);
    const selectPropertyId = useSelector((state) => state.login.selectedPropertyID);
    const userId = useSelector((state) => state.login.user.id);
    const companyId = useSelector((state) => state.login.company.id);
    const [reportSearch, setSearchInfo] = useState({});
    const [startDate, setStartDate] = useState(new Date().toISOString().substring(0, 10));
    const [endDate, setEndDate] = useState(new Date().toISOString().substring(0, 10));
    const [properties, setProperties] = useState([]);
    const [filteredName] = properties.filter((property) => property.id === selectPropertyId);
    const [selectedValue, setSelectedValue] = useState([]);

    const reportsForMulti = ['3', '2', '4', '16', '45', '51', '1', '64', '15', '60'];

    useEffect(() => {
        let isMounted = true;
        async function fetchData() {
            setLoading(true);
            try {
                if (isMounted && userId && selectPropertyId && companyId) {
                    const getReports = await reportsAPI.getReports();
                    const getUserProperties = await reportsAPI.getUserProperties({
                        uID: userId,
                    });

                    setProperties(getUserProperties);
                    const reportSearch = {
                        pID: selectPropertyId.toString(),
                        compID: companyId.toString(),
                        sDt: startDate || new Date().toISOString().substring(0, 10),
                        eDt: endDate || new Date().toISOString().substring(0, 10),
                        uID: userId,
                    };
                    setSearchInfo(reportSearch);
                    setReportOptions({
                        filterType: 'dropdown',
                        pagination: false,
                        selectableRows: 'none',
                    });
                    setFetchReports(getReports);
                }
            } catch (error) {
                // Handle errors if necessary
            } finally {
                if (isMounted) {
                    setLoading(false);
                }
            }
        }

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [userId, selectPropertyId, companyId]);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const getReports = await reportsAPI.getReports();
            const repId = getReports?.filter((report) => report.ReportID === +reportSearch.rID);
            if (reportsForMulti.includes(reportId))
                setSelectedValue(
                    selectedValue?.length > 0
                        ? selectedValue
                        : [
                              {
                                  name: `${filteredName?.PropertyName}`,
                                  id: `${selectPropertyId.toString()}`,
                              },
                          ],
                );
            reportSearch.pID = selectPropertyId.toString();

            setReportName(repId[0]?.Report);
            if (searchState) {
                setSearchInfo({ ...searchState, uID: userId });
                const repId = getReports?.filter((report) => report.ReportID === +searchState.rID);
                setReportName(repId[0]?.Report);
                setSelectedValue(propertyVal);
                setStartDate(searchState.sDt);
                setEndDate(searchState.eDt);
            }
            setLoading(false);
        }
        fetchData();
    }, [reportId, reportButton, selectPropertyId, searchState]);

    if (loading) {
        return (
            <RctCollapsibleCard colClasses="col-xs-12 col-sm-12 col-md-12" heading={'Loading Reports...'}>
                <LinearProgress />
            </RctCollapsibleCard>
        );
    }
    const handleReport = (event) => {
        reportSearch.rID = event.target.value;
        setReportId(event.target.value);
    };
    const handleStartDate = (date, formattedDate) => {
        const input = formattedDate;
        const [month, day, year] = input?.split('-');
        formattedDate = (year + '-' + month + '-' + day).toString();
        reportSearch.sDt = formattedDate;
        setStartDate(date);
    };
    const handleEndDate = (date, formattedDate) => {
        const input = formattedDate;
        const [month, day, year] = input.split('-');
        formattedDate = (year + '-' + month + '-' + day).toString();
        reportSearch.eDt = formattedDate;
        setEndDate(date);
    };
    const handleToday = () => {
        var currentDate = new Date().toISOString();
        reportSearch.sDt = currentDate.substring(0, 10);
        reportSearch.eDt = currentDate.substring(0, 10);
        setStartDate(currentDate);
        setEndDate(currentDate);
    };
    const handleMonth = () => {
        var currentDate = new Date();
        var firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString();
        var lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString();
        reportSearch.sDt = firstDay.substring(0, 10);
        reportSearch.eDt = lastDay.substring(0, 10);
        setStartDate(firstDay);
        setEndDate(lastDay);
    };
    const handleYear = () => {
        var currentDate = new Date();
        var firstDay = new Date(currentDate.getFullYear(), 0, 1).toISOString();
        var lastDay = new Date(currentDate.getFullYear(), 11, 31).toISOString();
        reportSearch.sDt = firstDay.substring(0, 10);
        reportSearch.eDt = lastDay.substring(0, 10);
        setStartDate(firstDay);
        setEndDate(lastDay);
    };
    const handleRunReport = () => {
        reportButton ? setReportButton(false) : setReportButton(true);
    };
    const onSelect = (selectedList, selectedItem) => {
        setSelectedValue(selectedList);
    };
    const onRemove = (selectedList, removedItem) => {
        setSelectedValue(selectedList);
    };
    const options = properties.map((property) => {
        return { name: property.PropertyName, id: property.id.toString() };
    });
    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr?.split('-');
        let newDate = `${month}-${day}-${year}`;
        return newDate;
    };
    return (
        <Main>
            <div className="formelements-wrapper" style={Constants.margins}>
                <div className="page-title d-flex justify-content-between align-items-center">
                    <div className="page-title-wrap">
                        <h2>
                            <span>Reports</span>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-xl-12">
                        <RctCollapsibleCard heading="">
                            <Form name="rID">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <FormGroup className="mr-10 mb-10">
                                            <Label for="reportType" className="mr-sm-10">
                                                Report
                                            </Label>
                                            <Input type="select" name="reportType" id="reportType" onChange={handleReport}>
                                                {!reportSearch.rID ? (
                                                    <option value="0">Select a report</option>
                                                ) : (
                                                    <option value={reportId} name={reportName}>
                                                        {reportName}
                                                    </option>
                                                )}

                                                {fetchReports
                                                    ?.filter((report) => !report.Report.includes('X-'))
                                                    .map((report) => {
                                                        return (
                                                            <option
                                                                key={report.ReportID}
                                                                value={report.ReportID}
                                                                name={report.Report}
                                                            >
                                                                {report.Report}
                                                            </option>
                                                        );
                                                    })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                    {(reportSearch.rID === '3' ||
                                        reportSearch.rID === '2' ||
                                        reportSearch.rID === '4' ||
                                        reportSearch.rID === '16' ||
                                        reportSearch.rID === '45' ||
                                        reportSearch.rID === '51' ||
                                        reportSearch.rID === '1' ||
                                        reportSearch.rID === '64' ||
                                        reportSearch.rID === '15' ||
                                        reportSearch.rID === '60') && (
                                        <div className="col-sm-2">
                                            <Label for="propertySelect" className="mr-sm-10">
                                                Select Properties
                                            </Label>
                                            <Multiselect
                                                options={options}
                                                selectedValues={selectedValue}
                                                onSelect={onSelect}
                                                onRemove={onRemove}
                                                displayValue="name"
                                            />
                                        </div>
                                    )}
                                    <div className="col-sm-2">
                                        <Label for="startDate" className="mr-sm-10">
                                            Start date
                                        </Label>
                                        <DatePicker
                                            id="startDate"
                                            value={startDate}
                                            onChange={(v, f) => handleStartDate(v, f)}
                                            dateFormat="MM-DD-YYYY"
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <Label for="endDate" className="mr-sm-10">
                                            End date
                                        </Label>
                                        <DatePicker
                                            id="endDate"
                                            value={endDate}
                                            onChange={(v, f) => handleEndDate(v, f)}
                                            dateFormat="MM-DD-YYYY"
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <Label for="adjustDate" className="mr-sm-10">
                                            Adjust Date
                                        </Label>
                                        <div id="adjustDate" className="d-flex">
                                            <div className="d-flex">
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(startDate).add('days', -1).format('YYYY-MM-DD');
                                                        reportSearch.sDt = t;
                                                        setStartDate(t);
                                                    }}
                                                >{`<<`}</a>
                                                <Button color="link" style={btnStyle} onClick={handleToday}>
                                                    Today
                                                </Button>
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(endDate).add('days', 1).format('YYYY-MM-DD');
                                                        reportSearch.eDt = t;
                                                        setEndDate(t);
                                                    }}
                                                >{`>>`}</a>
                                            </div>
                                            <div className="d-flex mx-4">
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(startDate).add('M', -1);
                                                        const start = t.startOf('M').format('YYYY-MM-DD');
                                                        const end = t.endOf('M').format('YYYY-MM-DD');
                                                        reportSearch.sDt = start;
                                                        reportSearch.eDt = end;
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                    }}
                                                >{`<<`}</a>
                                                <Button color="link" style={btnStyle} onClick={handleMonth}>
                                                    This month
                                                </Button>
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(endDate).add('M', 1);
                                                        const start = t.startOf('M').format('YYYY-MM-DD');
                                                        const end = t.endOf('M').format('YYYY-MM-DD');
                                                        reportSearch.sDt = start;
                                                        reportSearch.eDt = end;
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                    }}
                                                >{`>>`}</a>
                                            </div>
                                            <div className="d-flex">
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(startDate).add('Y', -1);
                                                        const start = t.startOf('Y').format('YYYY-MM-DD');
                                                        const end = t.endOf('Y').format('YYYY-MM-DD');
                                                        reportSearch.sDt = start;
                                                        reportSearch.eDt = end;
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                    }}
                                                >{`<<`}</a>
                                                <Button color="link" style={btnStyle} onClick={handleYear}>
                                                    This year
                                                </Button>
                                                <a
                                                    className="btn btn-link m-auto p-0"
                                                    onClick={() => {
                                                        const t = moment(endDate).add('Y', 1);
                                                        const start = t.startOf('Y').format('YYYY-MM-DD');
                                                        const end = t.endOf('Y').format('YYYY-MM-DD');
                                                        reportSearch.sDt = start;
                                                        reportSearch.eDt = end;
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                    }}
                                                >{`>>`}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    type="button"
                                    color="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={handleRunReport}
                                >
                                    Run report
                                </Button>
                            </Form>
                        </RctCollapsibleCard>
                    </div>
                </div>
                {reportName === '1099-INT' && (
                    <TenNinetyNineInt
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === '1099-MISC' && (
                    <TenNinetyNineMisc
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === '1099-NEC' && (
                    <TenNinetyNineNEC
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Application Summary' && (
                    <ApplicationSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Background Check Summary' && (
                    <BackgroundCheck
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Balance Sheet' && (
                    <BalanceSheet
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Box Score Report' && (
                    <BoxScore
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Budget - Projected' && (
                    <BudgetProject
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Budget - Projected vs Actual' && (
                    <BudgetProjectActual
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Chart Of Accounts' && (
                    <ChartOfAccounts
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Concession Report' && (
                    <Concession
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Credit Check Summary' && (
                    <CreditCheck
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Delinquency' && (
                    <Delinquency
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Delinquency by Date Range' && (
                    <DelinquencyDate
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Deposit Listing' && (
                    <DepositList
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Deposit Listing By Unit' && (
                    <DepositListUnit
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Down Unit Expense Summary' && (
                    <DownUnit
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Escrow Reserve Reimburse' && (
                    <EscrowReserve
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Eviction Summary' && (
                    <EvictionSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Expenses by Unit' && (
                    <ExpensesUnit
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'General Ledger' && (
                    <GeneralLedger
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Lease Expirations' && (
                    <LeaseExpiration
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Lease Renewals' && (
                    <LeaseRenewals
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Lease Renewals By Month' && (
                    <LeaseRenewalsMonth
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Lease Summary' && (
                    <LeaseSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Lease Violation' && (
                    <LeaseViolation
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Leasing Agent Productivity' && (
                    <LeasingAgent
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Move In / Move Out' && (
                    <MoveInOut
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Move Out Reason' && (
                    <MoveOutReason
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Non Allocated Payments' && (
                    <NonAllocPayments
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Non Recurring Charges' && (
                    <NonRecCharges
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'NSF' && (
                    <Nsf
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Occupancy By Month' && (
                    <OccupancyMonth
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Online Payments Summary' && (
                    <OnlinePaymentSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Payables' && (
                    <Payables
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Positive Credit Balance' && (
                    <PositiveCredit
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'PrePaid Rent' && (
                    <PrePaidRent
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Profit and Loss' && (
                    <ProfitAndLoss
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Profit and Loss by Month' && (
                    <ProfitAndLossByMonth
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Projected Occupancy' && (
                    <ProjectedOccupancy
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Quickbooks Export' && (
                    <Quickbooks
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Rent Roll' && (
                    <RentRoll
                        reportName={reportName}
                        properties={properties}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Rent Roll and Actuals' && (
                    <RentRollActual
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Security Deposit' && (
                    <SecurityDeposit
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Tenant Online Payments' && (
                    <TenantOnlinePayments
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Tenant Payments' && (
                    <TenantPayments
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Tenant Portal Accounts' && (
                    <TenantPortal
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Tenant Tenure' && (
                    <TenantTenure
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Tenants without proof of insurance' && (
                    <TenantInsurance
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Traffic Source' && (
                    <TrafficSource
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Traffic Source - All Traffic' && (
                    <TrafficSourceAll
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Transaction Change Log' && (
                    <TransChangeLog
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Unit Availability Listing' && (
                    <UnitAvailList
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Unit Listing' && (
                    <UnitListing
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Vacancy by Month' && (
                    <VacancyMonth
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Vacancy Report' && (
                    <VacancyReport
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Vehicle Report' && (
                    <VehicleReport
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'Work Order Summary' && (
                    <WorkOrderSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        selectedValue={selectedValue}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'X-Property Delinquency Summary' && (
                    <XDelinquencySummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'X-Property Tenant Payments' && (
                    <XTenantPayments
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'X-Property Vacancy Report' && (
                    <XVacancyReport
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
                {reportName === 'X-Property Work Order Summary' && (
                    <XWorkOrderSummary
                        reportName={reportName}
                        propertyId={selectPropertyId}
                        properties={properties}
                        reportSearch={reportSearch}
                        reportOptions={reportOptions}
                        startDate={formatDate(reportSearch.sDt)}
                        endDate={formatDate(reportSearch.eDt)}
                    />
                )}
            </div>
        </Main>
    );
};

export default Reports;
